<template>
  <el-dialog
    top="80px"
    width="500px"
    class="create-user-popup"
    title="Tạo mới tài khoản"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Loại tài khoản" prop="staffType">
        <el-select v-model="form.staffType" disabled class="w-100">
          <el-option
            v-for="(t, index) in types"
            :key="index"
            :label="t.label"
            :value="t.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Địa chỉ email" prop="email">
        <el-input v-model="form.email" :disabled="callingAPI" placeholder="Nhập email"></el-input>
      </el-form-item>
      <el-form-item label="Tên" prop="name">
        <el-input v-model="form.name" :disabled="callingAPI" placeholder="Nhập tên"></el-input>
      </el-form-item>
      <el-form-item label="Số điện thoại" prop="phone">
        <el-input v-model="form.phone" :disabled="callingAPI" placeholder="Nhập số điện thoại"></el-input>
      </el-form-item>
      <el-form-item label="Mật khẩu" prop="password">
        <el-input v-model="form.password" :disabled="callingAPI" placeholder="Nhập mật khẩu"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleCreateUser">Tạo mới</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { createUser } from '@/services/user'
import { getUserRoles } from '@/utils/filters'
export default {
  name: 'CreateUser',
  props: {
    visible: Boolean,
    type: String,
    staffType: String
  },
  data() {
    return {
      callingAPI: false,
      form: {
        email: '',
        name: '',
        phone: '',
        staffType: this.staffType,
        password: ''
      },
      rules: {
        email: [
          { required: true, message: 'Địa chỉ email không được để trống', trigger: ['blur', 'change'] },
          { type: 'email', message: 'Địa chỉ email không hợp lệ', trigger: ['blur', 'change'] }
        ],
        name: [
          { required: true, message: 'Tên không được để trống', trigger: ['blur', 'change'] }
        ],
        phone: [
          { required: true, message: 'Số điện thoại không được để trống', trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, message: 'Mật khẩu không được để trống', trigger: ['blur', 'change'] }
        ]
      },
      types: getUserRoles()
    }
  },
  computed: {
    dataInput() {
      let input = {
        email: this.form.email,
        name: this.form.name,
        phone: this.form.phone,
        user_type: this.type,
        password: this.form.password,
        avatar_url: ''
      }
      if (this.type === 'staff') {
        input = { ...input, staff_type: this.form.staffType }
      }
      return input
    }
  },
  methods: {
    handleCreateUser() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.callingAPI = true
          await createUser(this.dataInput).then(() => {
            this.$notify.success({
              title: 'Thành công',
              message: 'Tạo mới thành công'
            })
            this.$refs.form.resetFields()
            this.$emit('reload')
            this.callingAPI = false
          }).catch((error) => {
            if (error.response.data.error.includes('Taken email')) {
              this.$notify.error({
                title: 'Thất bại',
                message: 'Địa chỉ email đã được sử dụng. Vui lòng dùng địa chỉ email khác'
              })
            } else {
              this.$notify.error({
                title: 'Thất bại',
                message: 'Tạo mới thất bại'
              })
            }
            this.callingAPI = false
          })
        }
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
</style>
